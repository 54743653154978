import "./location-mexico.scss"

import { graphql } from 'gatsby'
import * as React from "react"
import FindDreamJob from "../../components/careers/finddreamjob"
import JobOpenings from "../../components/careers/jobopenings"
import LocationHeader from "../../components/careers/locationheader"
import WhyBTSLocations from "../../components/careers/whybtslocations"
import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"

export const query = graphql`
 query($language: String!) {
    allContentfulJobs(
      filter: { locations: { elemMatch: { country: { slug: { eq: "mexico" }, node_locale: { eq: "en-US" }}}}}
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        id
        jobSlug
        title
        updatedAt(formatString: "MM/DD/YY")
        remote
        office
        urgent 
        description {
          description
        }
        locations {
          id
          slug
          name
          shortName
        }
      }
    }
 locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

const LocationMexicoPage = ({ data }) => {
    const tepatitlan = {
        name: "Tepatitlan de Morelos",
        text: "Tepatitlan, home of our oldest and largest office outside the USA, offers a great work environment; a place where employees can go around using scooters, play pool, music, video games, and organize asados on the office's roof. Tepatitlan, the fastest growing city in Mexico is located only 45 minutes away from Guadalajara.",
        secondText: "The city offers a very good quality of life, excellent schools (regularly voted best schools of the state), many restaurants and bars, affordable housing, etc. It is the ideal place to grow a family."
    };

    const guadalajara = {
        name: "Guadalajara",
        text: "Guadalajara, the second largest city in Mexico, is often tagged as the Silicon Valley of Latin America. Over 700 tech companies have joined Intel, HP, IBM, Cisco, and the likes in establishing development centers in the land of mariachis.",
        secondText: "The comparison with San Francisco goes beyond the tech sector. Guadalajara is a very diverse, multicultural city that prides itself of its history, its many museums and historical buildings, and its vibrant social life. Concerts, sport events, delicious food contribute to make this city a great place to live. Last but not least, its proximity to the United States has played a critical role in the development of a premier tech community."
    }

    const queretaro = {
        name: "Queretaro",
        text: "Nested about 150 miles north of Mexico DF, Queretaro has attracted an impressive number of International companies, including Samsung, General Electric, Colgate and Procter & Gamble, to name a few. It is also an important hub for automotive, aerospace, and technology.",
        secondText: "In the tech sector, Axtel invested over $ 60M to build data centers, Daewoo followed shortly after with a $100 million investment to build a new platform in the Metropolitan area. In 2017, Deloitte chose Queretaro to set up its 4th regional Technology service center worldwide. Ericsson of Sweden and Safran of France launched operations in Queretaro in 2013, where Tata Consulting was already established.",
        thirdText: "Among all Mexican cities, Queretaro is recognized as the safest and the one with the best quality of life. It has the 2nd highest GDP per capita among Mexican urban centers. The region is also famous for its production of wine and cheese."
    }

    const [cityName, setCityName] = React.useState(tepatitlan.name);
    const [cityText, setCityText] = React.useState(tepatitlan.text);
    const [secondText, setSecondText] = React.useState(tepatitlan.secondText);
    const [thirdText, setThirdText] = React.useState(tepatitlan.thirdText);
    const pageTitle = "Careers - Mexico"
    const pageDescription = "Get to Know Our Mexico Offices"
    const lang = 'EN'
    const meta = []

    const breadcrumbMap = [
        { title: "Careers", url: "/careers/" },
        { title: "Locations", url: "/careers/locations/" },
        { title: "Mexico", url: "#" }
    ]

    const location = {
        title: "Mexico",
        cities: ["Tepatitlan de Morelos", "Guadalajara", "Queretaro"],
        sectionClassName: "section-location-mexico",
    }

    var {
        nodes: jobs
    } = data.allContentfulJobs;

    const jobsList = jobs.map(({ jobSlug, title, locations, description, remote, relocation, office, urgent }) => (
        {
            title: title,
            location: locations.map((item) => (item["shortName"])).join(" / "),
            url: "/careers/jobs/" + jobSlug + "/",
            urgent: urgent,
            tags: { "remote": remote, "office": office, "relocation": relocation },
            introduction: description ? description.description.slice(0, 220) + "..." : ""
        }
    ));

    const setCity = (name) => {
        setCityName(name);
        switch (name) {
            case "Tepatitlan de Morelos":
                setCityText(tepatitlan.text);
                setSecondText(tepatitlan.secondText);
                setThirdText('');
                break;
            case "Guadalajara":
                setCityText(guadalajara.text);
                setSecondText(guadalajara.secondText);
                setThirdText('');
                break;
            case "Queretaro":
                setCityText(queretaro.text);
                setSecondText(queretaro.secondText);
                setThirdText(queretaro.thirdText);
                break;
            default:
                break;
        }
    }

    const cities = location.cities.map((name, index) =>
        <div hidden={name === cityName} key={index} className="list-item" onClick={() => setCity(name)}>{name}</div>
    );

    return (
        <>
            <Layout breadcrumbMap={breadcrumbMap}>
                <Seo title={pageTitle} description={pageDescription} lang={lang} meta={meta} />
                <div className="max-container-pages">
                    <LocationHeader location={location} city={cityName}>
                        <span className="city-title">{cityName}</span>
                        <p className="city-text">
                            {cityText}
                        </p>

                        {secondText &&
                            <p>{secondText}</p>
                        }

                        {thirdText &&
                            <p>{thirdText}</p>
                        }
                        <div className="other-locations">
                            <h5 className="other-locations-title">Other offices in Mexico</h5>
                            <div className="other-locations-content">
                                {cities}
                            </div>
                        </div>
                    </LocationHeader>
                    <WhyBTSLocations reverse={true}></WhyBTSLocations>
                    <JobOpenings jobs={jobsList} search="Mexico"></JobOpenings>
                    <FindDreamJob></FindDreamJob>
                </div>
            </Layout>
        </>
    )
}

export default LocationMexicoPage
